import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../Footer";
import Header from "../Header";
import Banner from "./Banner";
import Partner from "./Partner";
import Feature from "./Feature";
import AboutUs from "./AboutUs";
import Challenges from "./Challenges";
import ServicesArea from "./ServicesArea";
import Pricing from "./Pricing";
import CounterArea from "./CounterArea";
import BlogArea from "./BlogArea";

function Home() {
  return (
    <div>
      {/* <!-- Start Header Area --> */}
      <Header />
      {/* <!-- End Header Area --> */}

      {/* <!-- Start Banner Area --> */}
      {/* <section
        className="banner-area banner-area-five bg-5 jarallax"
        data-jarallax='{"speed": 0.3}'
      >
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="banner-content">
                <img
                  src="assets/img/banner/banner-logo.png"
                  className="wow fadeInDown"
                  data-wow-delay="1s"
                  alt="Image"
                />
                <span className="top-title wow fadeInDown" data-wow-delay="1s">
                  World className Cyber Security
                </span>
                <h1 className="wow fadeInDown" data-wow-delay="1s">
                  Real-Time Monitoring Your Infrastructure
                </h1>
                <p className="wow fadeInUp" data-wow-delay="1s">
                  Cybersecurity is the protection from the theft to protect of
                  our computer systems and networks or being damaged of our
                  hardware and software.
                </p>

                <div className="banner-btn wow fadeInUp" data-wow-delay="1s">
                  <a href="about.html" className="default-btn">
                    <span>About Us</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Banner />
      {/* <!-- End Banner Area --> */}

      {/* <!-- Start Partner Area --> */}
      {/* <div className="partner-area bg-color ptb-100">
        <div className="container">
          <OwlCarousel
            className="partner-slider owl-theme owl-carousel"
            {...partnerSlider}
          >
            <div className="partner-item">
              <a href="#">
                <img src="assets/img/partner/partner-1.png" alt="Image" />
              </a>
            </div>

            <div className="partner-item">
              <a href="#">
                <img src="assets/img/partner/partner-2.png" alt="Image" />
              </a>
            </div>

            <div className="partner-item">
              <a href="#">
                <img src="assets/img/partner/partner-3.png" alt="Image" />
              </a>
            </div>

            <div className="partner-item">
              <a href="#">
                <img src="assets/img/partner/partner-4.png" alt="Image" />
              </a>
            </div>

            <div className="partner-item">
              <a href="#">
                <img src="assets/img/partner/partner-5.png" alt="Image" />
              </a>
            </div>
          </OwlCarousel>
        </div>
      </div> */}
      <Partner />
      {/* <!-- End Partner Area --> */}

      {/* <!-- Start Feature Area --> */}
      {/* <section className="feature-area feature-area-two feature-area-five pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="single-feature overly-one">
                <div className="overly-two">
                  <div className="title">
                    <i className="flaticon-testing"></i>
                    <h3>Pen Testing</h3>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <div className="feature-shape">
                    <img src="assets/img/feature-shape.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-feature overly-one">
                <div className="overly-two">
                  <div className="title">
                    <i className="flaticon-cybercrime"></i>
                    <h3>Cyber Threat Hunting</h3>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <div className="feature-shape">
                    <img src="assets/img/feature-shape.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
              <div className="single-feature overly-one">
                <div className="overly-two">
                  <div className="title">
                    <i className="flaticon-cyber-security"></i>
                    <h3>Security Center</h3>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <div className="feature-shape">
                    <img src="assets/img/feature-shape.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Feature />
      {/* <!-- End Feature Area --> */}

      {/* <!-- Start About Us Area --> */}
      {/* <section className="about-us-area pb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img src="assets/img/about-img-5.jpg" alt="Image" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content">
                <div className="about-title">
                  <span>About Us</span>
                  <h2>
                    Without stopping for a moment we give you best technology
                    experience discussing from our expertise to stop threats
                    being theft or damaged
                  </h2>
                </div>

                <div className="tab">
                  <ul className="tabs">
                    <li>Our Experience</li>
                    <li>Why Us?</li>
                    <li>Our Approach</li>
                  </ul>

                  <div className="tab_content">
                    <div className="tabs_item">
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Placeat atque quibusdam fuga natus non
                        necessitatibus eveniet maiores nostrum esse ut
                        voluptates sint dolores, voluptatum consequatur ad est
                        enim perferendis reprehenderit.
                      </p>

                      <ul>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Trusted Partner
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Product Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          System Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Operational Security
                        </li>
                      </ul>
                    </div>

                    <div className="tabs_item">
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Placeat atque quibusdam fuga natus non
                        necessitatibus eveniet maiores nostrum esse ut
                        voluptates sint dolores, voluptatum consequatur ad est
                        enim perferendis reprehenderit.
                      </p>

                      <ul>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Trusted Partner
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Product Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          System Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Operational Security
                        </li>
                      </ul>
                    </div>

                    <div className="tabs_item">
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Placeat atque quibusdam fuga natus non
                        necessitatibus eveniet maiores nostrum esse ut
                        voluptates sint dolores, voluptatum consequatur ad est
                        enim perferendis reprehenderit.
                      </p>

                      <ul>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Trusted Partner
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Product Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          System Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Operational Security
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <AboutUs />
      {/* <!-- End About Us Area --> */}

      {/* <!-- Start Our Challenges Area --> */}
      {/* <section className="challenges-area challenges-area-two pt-100 pb-70">
        <div className="container">
          <div className="section-title white-title">
            <span>Our Challenges</span>
            <h2>You Can Protect Your Organization’s Cybersecurity By Us</h2>
          </div>

          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-challenges overly-one">
                <div className="overly-two">
                  <i className="flaticon-threat"></i>
                  <h3>Identifying Threats</h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>
                  <span className="flaticon-threat"></span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-challenges overly-one">
                <div className="overly-two">
                  <i className="flaticon-cyber"></i>
                  <h3>Cyber Risk Assessment</h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>
                  <span className="flaticon-cyber"></span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-challenges overly-one">
                <div className="overly-two">
                  <i className="flaticon-cyber-security-1"></i>
                  <h3>Testing Cyber Security</h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>
                  <span className="flaticon-cyber-security-1"></span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-challenges overly-one">
                <div className="overly-two">
                  <i className="flaticon-password"></i>
                  <h3>Managing Cloud Security</h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>
                  <span className="flaticon-password"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="challenges-shape">
          <img src="assets/img/challenges-shape.png" alt="Image" />
        </div>
      </section> */}
      <Challenges />
      {/* <!-- End Our Challenges Area --> */}

      {/* <!-- Start Services Area --> */}
      <section className="services-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>Cybersecurity Services</span>
            <h2>
              You Can Protect Your Organization’s Cybersecurity By Services Us
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-1.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Website Scanning</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read More
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-2.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Malware Removal</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read More
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-3.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Cloud Security</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read More
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-4.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Data Protection</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read More
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ServicesArea />
      {/* <!-- End Services Area --> */}

      {/* <!-- Start Pricing Area --> */}
      {/* <section className="pricing-area bg-color pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>Security Packages</span>
            <h2>We Have Different Types Of Pricing Plans</h2>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing overly-one">
                <div className="overly-two">
                  <div className="pricing-title">
                    <h3>Basic</h3>
                    <h2>Free</h2>
                    <span>Per Month</span>
                    <h4>Features</h4>
                  </div>

                  <ul>
                    <li>30 Days Product Testing</li>
                    <li>Upgrade Anytime Protection</li>
                    <li>Unlimited Malware Removal</li>
                    <li>24/7 Live Assistance</li>
                    <li>Scan Every 12 Hrs</li>
                    <li>Configure Software</li>
                  </ul>

                  <a href="#" className="default-btn">
                    <span>Read More</span>
                  </a>

                  <div className="pricing-shape">
                    <img src="assets/img/pricing-shape.png" alt="Image" />
                  </div>
                  <div className="pricing-shape-2">
                    <img src="assets/img/pricing-shape-2.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-pricing overly-one active">
                <div className="overly-two">
                  <div className="pricing-title">
                    <h3>Popular</h3>
                    <h2>
                      <sub>$</sub>29
                    </h2>
                    <span>Per Month</span>
                    <h4>Features</h4>
                  </div>

                  <ul>
                    <li>40 Days Product Testing</li>
                    <li>Upgrade Anytime Protection</li>
                    <li>Unlimited Malware Removal</li>
                    <li>24/7 Live Assistance</li>
                    <li>Scan Every 13 Hrs</li>
                    <li>Configure Software</li>
                  </ul>

                  <a href="#" className="default-btn">
                    <span>Read More</span>
                  </a>

                  <div className="pricing-shape">
                    <img src="assets/img/pricing-shape.png" alt="Image" />
                  </div>
                  <div className="pricing-shape-2">
                    <img src="assets/img/pricing-shape-2.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-pricing overly-one">
                <div className="overly-two">
                  <div className="pricing-title">
                    <h3>Basic</h3>
                    <h2>
                      <sub>$</sub>59
                    </h2>
                    <span>Per Month</span>
                    <h4>Features</h4>
                  </div>

                  <ul>
                    <li>50 Days Product Testing</li>
                    <li>Upgrade Anytime Protection</li>
                    <li>Unlimited Malware Removal</li>
                    <li>24/7 Live Assistance</li>
                    <li>Scan Every 14 Hrs</li>
                    <li>Configure Software</li>
                  </ul>

                  <a href="#" className="default-btn">
                    <span>Read More</span>
                  </a>

                  <div className="pricing-shape">
                    <img src="assets/img/pricing-shape.png" alt="Image" />
                  </div>
                  <div className="pricing-shape-2">
                    <img src="assets/img/pricing-shape-2.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Pricing />
      {/* <!-- End Pricing Area --> */}

      {/* <!-- Start Counter Area --> */}
      {/* <section
        className="counter-area pt-100 pb-70 jarallax"
        data-jarallax='{"speed": 0.3}'
      >
        <div className="container">
          <div className="counter-max-wide">
            <div className="section-title white-title">
              <span>Why Choose Us</span>
              <h2>
                We have a lot of skills in cyber security. We have a group of
                fighters who are always working on cyber security.
              </h2>
            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="single-counter overly-one">
                  <div className="overly-two">
                    <i className="flaticon-authentication"></i>
                    <h2>
                      <span
                        className="odometer"
                        class="prg-count"
                        data-count="365"
                      >
                        00
                      </span>
                    </h2>
                    <h3>Clients Protection</h3>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-6">
                <div className="single-counter overly-one">
                  <div className="overly-two">
                    <i className="flaticon-reliability"></i>
                    <h2>
                      <span
                        className="odometer"
                        class="prg-count"
                        data-count="1000"
                      >
                        00
                      </span>
                    </h2>
                    <h3>Trusted Organizations</h3>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-6">
                <div className="single-counter overly-one">
                  <div className="overly-two">
                    <i className="flaticon-web-protection"></i>
                    <h2>
                      <span
                        className="odometer"
                        class="prg-count"
                        data-count="567"
                      >
                        00
                      </span>
                    </h2>
                    <h3>Website Protection</h3>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-6">
                <div className="single-counter overly-one">
                  <div className="overly-two">
                    <i className="flaticon-innovation"></i>
                    <h2>
                      <span
                        className="odometer"
                        class="prg-count"
                        data-count="100"
                      >
                        00
                      </span>
                      <span className="target">%</span>
                    </h2>
                    <h3>Innovative Technology</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <CounterArea />
      {/* <!-- End Counter Area --> */}

      {/* <!-- Start Team Area --> */}
      <section className="team-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>Professionals</span>
            <h2>Meet Our Skilled Team</h2>
          </div>

          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="single-team">
                <img src="assets/img/team/team-img-1.jpg" alt="Image" />

                <div className="team-content">
                  <h3>Ciaran Sebastian</h3>
                  <span>Commercial Director</span>

                  <ul>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-team">
                <img src="assets/img/team/team-img-2.jpg" alt="Image" />

                <div className="team-content">
                  <h3>Jendoubi Bayer</h3>
                  <span>Financial Officer</span>

                  <ul>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
              <div className="single-team">
                <img src="assets/img/team/team-img-3.jpg" alt="Image" />

                <div className="team-content">
                  <h3>Andrew Gleeson</h3>
                  <span>Chief Executive Officer</span>

                  <ul>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Team Area --> */}

      {/* <!-- Start Get In Touch Area --> */}
      <section className="get-in-touch-area get-in-touch-area-two bg-color ptb-100">
        <div className="container">
          <div className="section-title">
            <span>Get In Touch</span>
            <h2>
              Contact Us Today To Speak With An Expert About Your Specific Needs
            </h2>
          </div>

          <form className="get-in-touch-form">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" className="form-control" id="First-Name" />
                </div>
              </div>

              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" className="form-control" id="Email" />
                </div>
              </div>

              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <label>Phone</label>
                  <input type="text" className="form-control" id="Number" />
                </div>
              </div>

              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <label>Company</label>
                  <input type="text" className="form-control" id="Company" />
                </div>
              </div>
            </div>

            <button type="submit" className="default-btn">
              <span>Consultation</span>
            </button>
          </form>
        </div>

        <div className="get-in-touch-shape">
          <img src="assets/img/get-in-touch-shape.png" alt="Image" />
        </div>
      </section>
      {/* <!-- Eed Get In Touch Area --> */}

      {/* <!-- Start Blog Area --> */}
      {/* <section className="blog-area blog-area-four pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>Latest News</span>
            <h2>Read The Latest Articles Fromus</h2>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-blog">
                <div className="blog-img">
                  <a href="blog-details.html">
                    <img src="assets/img/blog/blog-1.jpg" alt="Image" />
                  </a>
                </div>

                <div className="blog-content">
                  <span>January 20, 2021</span>
                  <h3>
                    <a href="blog-details.html">Secure The Network</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>

                  <a href="blog-details.html" className="read-more">
                    Read More
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-blog">
                <div className="blog-img">
                  <a href="blog-details.html">
                    <img src="assets/img/blog/blog-2.jpg" alt="Image" />
                  </a>
                </div>

                <div className="blog-content">
                  <span>January 19, 2021</span>
                  <h3>
                    <a href="blog-details.html">Secure Security Advisory</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>

                  <a href="blog-details.html" className="read-more">
                    Read More
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog">
                <div className="blog-img">
                  <a href="blog-details.html">
                    <img src="assets/img/blog/blog-3.jpg" alt="Image" />
                  </a>
                </div>

                <div className="blog-content">
                  <span>January 18, 2021</span>
                  <h3>
                    <a href="blog-details.html">Cyber Threat Actors</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>

                  <a href="blog-details.html" className="read-more">
                    Read More
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <BlogArea />
      {/* <!-- End Blog Area --> */}

      {/* <!-- Start Subscribe Area --> */}
      <section className="subscribe-area pb-100">
        <div className="container">
          <div className="subscribe-bg">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6">
                <div className="subscribe-content">
                  <img src="assets/img/subscribe-img.png" alt="Image" />
                  <h3>Sing up for newsletter</h3>
                  <span>
                    Subscribe to the newsletter for all the latest updates
                  </span>
                </div>
              </div>

              <div className="col-lg-6 col-sm-6">
                <form className="newsletter-form" data-toggle="validator">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email address"
                    name="EMAIL"
                    required=""
                    autoComplete="off"
                  />

                  <button className="default-btn" type="submit">
                    Subscribe
                  </button>

                  <div id="validator-newsletter" className="form-result"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Subscribe Area --> */}

      {/* <!-- Start Footer Area --> */}
      <Footer />
      {/* <!-- End Footer Area --> */}
    </div>
  );
}

export default Home;

const ServicesArea = () => {
  return (
    <section className="services-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span>Our Services</span>
          <h2>
            Enhancing Your Business with Our Web & App Development Services
          </h2>
        </div>

        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="single-services">
              <div className="services-img">
                <a href="services-details.html">
                  <img src="assets/img/services/services-1.jpg" alt="Image" />
                </a>
              </div>

              <div className="services-content">
                <h3>
                  <a href="services-details.html">Custom App Development</a>
                </h3>
                <p>
                  Tailored mobile app solutions that cater to your unique
                  business requirements and user needs.
                </p>

                <a href="services-details.html" className="read-more">
                  Read More
                  <i className="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-services">
              <div className="services-img">
                <a href="services-details.html">
                  <img src="assets/img/services/services-2.jpg" alt="Image" />
                </a>
              </div>

              <div className="services-content">
                <h3>
                  <a href="services-details.html">E-commerce Development</a>
                </h3>
                <p>
                  Robust and scalable e-commerce platforms designed to boost
                  your online retail presence.
                </p>

                <a href="services-details.html" className="read-more">
                  Read More
                  <i className="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-services">
              <div className="services-img">
                <a href="services-details.html">
                  <img src="assets/img/services/services-3.jpg" alt="Image" />
                </a>
              </div>

              <div className="services-content">
                <h3>
                  <a href="services-details.html">CRM Solutions</a>
                </h3>
                <p>
                  Efficient CRM solutions to streamline your customer
                  relationships and enhance engagement.
                </p>

                <a href="services-details.html" className="read-more">
                  Read More
                  <i className="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-services">
              <div className="services-img">
                <a href="services-details.html">
                  <img src="assets/img/services/services-4.jpg" alt="Image" />
                </a>
              </div>

              <div className="services-content">
                <h3>
                  <a href="services-details.html">WhatsApp Bulk Messaging</a>
                </h3>
                <p>
                  Advanced messaging solutions to expand your reach and
                  streamline communication channels.
                </p>

                <a href="services-details.html" className="read-more">
                  Read More
                  <i className="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesArea;

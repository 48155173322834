import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

function Team() {
    return (
        <div>
            {/* <!-- Start Header Area --> */}
            <Header />
            {/* <!-- End Header Area --> */}


            {/* <!-- Start Page Title Area --> */}
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Our Team</h2>
                        <ul>
                            <li>
                                <Link to="/">
                                    <i className="bx bx-home"></i>
                                    Home
                                </Link>
                            </li>
                            <li className="active">Team</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <!-- End Page Title Area --> */}

            {/* <!-- Start Team Area --> */}
            <section className="team-area pt-100 pb-70">
                <div className="container">
                    <div className="section-title">
                        <span>Professionals</span>
                        <h2>Meet our skilled team</h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-team">
                                <img src="assets/img/team/team-img-1.jpg" alt="Image" />

                                <div className="team-content">
                                    <h3>Ciaran Sebastian</h3>
                                    <span>Commercial Director</span>

                                    <ul>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-team">
                                <img src="assets/img/team/team-img-2.jpg" alt="Image" />

                                <div className="team-content">
                                    <h3>Jendoubi Bayer</h3>
                                    <span>Financial Officer</span>

                                    <ul>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-team">
                                <img src="assets/img/team/team-img-3.jpg" alt="Image" />

                                <div className="team-content">
                                    <h3>Andrew Gleeson</h3>
                                    <span>Chief Executive Officer</span>

                                    <ul>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-team">
                                <img src="assets/img/team/team-img-4.jpg" alt="Image" />

                                <div className="team-content">
                                    <h3>Michael Jettkant</h3>
                                    <span>Cyber Security Technician</span>

                                    <ul>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-team">
                                <img src="assets/img/team/team-img-5.jpg" alt="Image" />

                                <div className="team-content">
                                    <h3>Linda Slivorski</h3>
                                    <span>Project Technician</span>

                                    <ul>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-team">
                                <img src="assets/img/team/team-img-6.jpg" alt="Image" />

                                <div className="team-content">
                                    <h3>Sharrell Jespersen</h3>
                                    <span>Data Center Technician</span>

                                    <ul>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Team Area --> */}


            {/* <!-- Start Footer Area --> */}
            <Footer />
            {/* <!-- End Footer Area --> */}
        </div>
    )
}

export default Team
const AboutUs = () => {
  return (
    <section className="about-us-area pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img">
              <img src="assets/img/about-img-5.jpg" alt="Image" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content">
              <div className="about-title">
                <span>About Us</span>
                <h2>
                  Empowering Your Digital Journey with Custom Web & App
                  Solutions
                </h2>
              </div>

              <div className="tab">
                <ul className="tabs">
                  <li>Our Experience</li>
                  <li>Why Us?</li>
                  <li>Our Approach</li>
                </ul>

                <div className="tab_content">
                  <div className="tabs_item">
                    <p>
                      Leveraging our extensive experience in web and app
                      development, we specialize in delivering customized,
                      user-centric solutions. Our expertise ranges from
                      innovative e-commerce platforms to efficient CRM
                      integrations.
                    </p>

                    <ul>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Comprehensive Web Development
                      </li>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Advanced App Solutions
                      </li>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Dynamic E-commerce Systems
                      </li>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Seamless CRM Integration
                      </li>
                    </ul>
                  </div>

                  <div className="tabs_item">
                    <p>
                      Opt for our services for a blend of creativity and
                      technology. We focus on delivering high-quality, scalable
                      solutions that align with your business objectives,
                      ensuring enhanced customer engagement and growth.
                    </p>

                    <ul>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Client-Focused Delivery
                      </li>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Innovative Solutions
                      </li>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Scalable and Sustainable
                      </li>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Continuous Support and Evolution
                      </li>
                    </ul>
                  </div>

                  <div className="tabs_item">
                    <p>
                      Our approach integrates your unique business needs with
                      our technical expertise. We believe in a collaborative,
                      agile methodology, ensuring solutions are not just
                      functional but also adaptive to evolving market trends.
                    </p>

                    <ul>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Agile Development Process
                      </li>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Customized Strategy Implementation
                      </li>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Emphasis on User Experience
                      </li>
                      <li>
                        <i className="bx bx-check-circle"></i>
                        Forward-Thinking Design
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

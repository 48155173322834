const Pricing = () => {
  return (
    <section className="pricing-area bg-color pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span>Our Pricing Plans</span>
          <h2>Choose the Plan That’s Right for Your Business</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-pricing overly-one">
              <div className="overly-two">
                <div className="pricing-title">
                  <h3>Starter</h3>
                  <h2>₹999</h2>
                  <span>Per Month</span>
                  <h4>Features</h4>
                </div>

                <ul>
                  <li>Basic App Development Support</li>
                  <li>Monthly Performance Review</li>
                  <li>Email Support</li>
                  <li>Access to Standard Features</li>
                  <li>Community Forum Access</li>
                </ul>

                <a href="#" className="default-btn">
                  <span>Read More</span>
                </a>

                <div className="pricing-shape">
                  <img src="assets/img/pricing-shape.png" alt="Image" />
                </div>
                <div className="pricing-shape-2">
                  <img src="assets/img/pricing-shape-2.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-pricing overly-one active">
              <div className="overly-two">
                <div className="pricing-title">
                  <h3>Professional</h3>
                  <h2>₹4,999</h2>
                  <span>Per Month</span>
                  <h4>Features</h4>
                </div>

                <ul>
                  <li>Advanced App Development Features</li>
                  <li>Weekly Performance Analytics</li>
                  <li>Priority Email and Call Support</li>
                  <li>Access to Premium Tools</li>
                  <li>Exclusive Community Webinars</li>
                </ul>

                <a href="#" className="default-btn">
                  <span>Read More</span>
                </a>

                <div className="pricing-shape">
                  <img src="assets/img/pricing-shape.png" alt="Image" />
                </div>
                <div className="pricing-shape-2">
                  <img src="assets/img/pricing-shape-2.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
            <div className="single-pricing overly-one">
              <div className="overly-two">
                <div className="pricing-title">
                  <h3>Enterprise</h3>
                  <h2>₹9,999</h2>
                  <span>Per Month</span>
                  <h4>Features</h4>
                </div>

                <ul>
                  <li>Comprehensive App Development Suite</li>
                  <li>Dedicated Account Manager</li>
                  <li>24/7 Priority Support</li>
                  <li>Customizable Feature Set</li>
                  <li>Strategic Planning and Consulting</li>
                </ul>

                <a href="#" className="default-btn">
                  <span>Read More</span>
                </a>

                <div className="pricing-shape">
                  <img src="assets/img/pricing-shape.png" alt="Image" />
                </div>
                <div className="pricing-shape-2">
                  <img src="assets/img/pricing-shape-2.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;

import React from "react";

function Footer() {
  return (
    <div>
      {/* <!-- Start Footer Area --> */}
      <footer
        className="footer-area pt-100 pb-70 jarallax"
        data-jarallax='{"speed": 0.3}'
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <a href="index.html" className="logo">
                  <div style={{ display: "flex", alignItems: "end", gap: 2 }}>
                    <img
                      src="assets/img/logonew.png"
                      alt="Logo"
                      style={{ width: 50, height: 50 }}
                    />
                    <h3 style={{ color: "white", padding: 0, margin: 10 }}>
                      Unicron Apps
                    </h3>
                  </div>
                </a>

                <p>
                  Welcome to Unicron Apps, your trusted partner for all your web
                  and mobile development needs. We are passionate about turning
                  your ideas into innovative digital solutions that drive
                  success. Our team of expert developers, designers, and
                  strategists work collaboratively to deliver high-quality
                  websites and mobile apps tailored to your unique requirements.
                </p>

                <ul className="social-icon">
                  <li>
                    <a href="#">
                      <i className="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="bx bxl-linkedin-square"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="bx bxl-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Contact Us</h3>

                <ul className="address">
                  <li className="location">
                    <i className="bx bxs-location-plus"></i> Huda Sec-2 Palwal
                    (NCR), Haryana
                  </li>
                  <li>
                    <i className="bx bxs-envelope"></i>
                    <a href="/cdn-cgi/l/email-protection#b3dbd6dfdfdcf3c0d6c2c7ca9dd0dcde">
                      <span
                        className="__cf_email__"
                        data-cfemail="e38b868f8f8ca3908692979acd808c8e"
                      >
                        unicronapps@outlook.com
                      </span>
                    </a>
                    <a href="#">skype: yourskypeid</a>
                  </li>
                  <li>
                    <i className="bx bxs-phone-call"></i>
                    <a href="tel:+91-8448804428">+91 8448804428</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Services</h3>

                <ul className="import-link">
                  <li>
                    <a href="#">Website Development</a>
                  </li>
                  <li>
                    <a href="#">Mobile App Development</a>
                  </li>
                  <li>
                    <a href="#">E-commerce Solutions</a>
                  </li>
                  <li>
                    <a href="#">Digital Marketing</a>
                  </li>
                  <li>
                    <a href="#">Custom Software Development</a>
                  </li>
                  <li>
                    <a href="#">UI/UX Design</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Resources</h3>

                <ul className="import-link">
                  <li>
                    <a href="#">Blog</a>
                  </li>
                  <li>
                    <a href="#">Client Testimonials</a>
                  </li>
                  <li>
                    <a href="#">Portfolio</a>
                  </li>
                  <li>
                    <a href="#">FAQs</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- End Footer Area --> */}

      {/* <!-- Start Copy Right Area --> */}
      <div className="copy-right-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                Copyright &copy; {new Date().getFullYear()} Unicron Apps and
                Website. All Rights Reserved.
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <ul className="footer-menu">
                <li>
                  <a href="privacy-policy.html" target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="terms-conditions.html" target="_blank">
                    Terms & Conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Copy Right Area --> */}

      {/* <!-- Start Go Top Area --> */}
      <div className="go-top">
        <i className="bx bx-chevrons-up"></i>
        <i className="bx bx-chevrons-up"></i>
      </div>
      {/* <!-- End Go Top Area --> */}
    </div>
  );
}

export default Footer;

const Feature = () => {
  return (
    <section className="feature-area feature-area-two feature-area-five pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-feature overly-one">
              <div className="overly-two">
                <div className="title">
                  <i className="flaticon-testing"></i> {/* Icon updated */}
                  <h3>Custom App Development</h3>
                </div>
                <p>
                  Expertise in creating bespoke mobile applications tailored to
                  your business needs, ensuring seamless user experience.
                </p>
                <div className="feature-shape">
                  <img src="assets/img/feature-shape.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-feature overly-one">
              <div className="overly-two">
                <div className="title">
                  <i className="flaticon-cybercrime"></i> {/* Icon updated */}
                  <h3>E-commerce Solutions</h3>
                </div>
                <p>
                  Specializing in E-commerce & E-commerce CRM Solutions,
                  providing robust platforms for seamless online retail
                  experiences.
                </p>
                <div className="feature-shape">
                  <img src="assets/img/feature-shape.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
            <div className="single-feature overly-one">
              <div className="overly-two">
                <div className="title">
                  <i className="flaticon-cyber-security"></i>
                  {/* Icon updated */}
                  <h3>WhatsApp Bulk Messaging</h3>
                </div>
                <p>
                  Offering advanced WhatsApp Bulk Messaging solutions to enhance
                  communication and marketing strategies for your business.
                </p>
                <div className="feature-shape">
                  <img src="assets/img/feature-shape.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;

import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

function TermsCondition() {
    return (
        <div>
            {/* <!-- Start Header Area --> */}
            <Header />
            {/* <!-- End Header Area --> */}
            {/* <!-- Start Page Title Area --> */}
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Terms & Conditions</h2>
                        <ul>
                            <li>
                                <Link to="/">
                                    <i className="bx bx-home"></i>
                                    Home
                                </Link>
                            </li>
                            <li className="active">Terms & Conditions</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <!-- End Page Title Area --> */}

            {/* <!-- Start Terms & Conditions Area --> */}
            <section className="terms-conditions-area ptb-100">
                <div className="container">
                    <div className="terms-conditions">
                        <div className="title">
                            <span>Information & notices</span>
                            <h2>Blackswan Technologies Terms of Service</h2>
                        </div>

                        <div className="conditions-content">
                            <h3>1. Ownership of site agreement to terms of use</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>

                            <ul>
                                <li>It has survived not only five centuries, but also the leap into electronic typesetting.</li>
                                <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                                <li>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</li>
                                <li>Various versions have evolved over the years, sometimes by accident sometimes on purpose.</li>
                            </ul>
                        </div>

                        <div className="conditions-content">
                            <h3>2. General Conditions</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
                        </div>

                        <div className="conditions-content">
                            <h3>3. Modifications to the Service</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
                        </div>

                        <div className="conditions-content">
                            <h3>4. Entire Agreement</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>

                            <ul>
                                <li>It has survived not only five centuries, but also the leap into electronic typesetting.</li>
                                <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                                <li>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</li>
                                <li>Various versions have evolved over the years, sometimes by accident sometimes on purpose.</li>
                            </ul>
                        </div>

                        <div className="conditions-content">
                            <h3>5. Service Rules</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                        </div>

                        <div className="conditions-content five mb-0">
                            <h3>6. Limitation of Liability</h3>
                            <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Terms & Conditions Area --> */}
            {/* <!-- Start Footer Area --> */}
            <Footer />
            {/* <!-- End Footer Area --> */}
        </div>
    )
}

export default TermsCondition
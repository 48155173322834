import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

function Solutions() {
  return (
    <div>
      <Header />

      <div className="page-title-area page-title-style-two">
        <div className="container">
          <div className="page-title-content">
            <h2>Solutions</h2>
            <ul>
              <li>
                <Link to="/">
                  <i className="bx bx-home"></i>
                  Home
                </Link>
              </li>
              <li className="active">Solutions</li>
            </ul>
          </div>
        </div>
      </div>

      <section className="solution-area pt-100 pb-70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="solution-content">
                <div className="solution-title">
                  <span>Comprehensive Digital Solutions</span>
                  <h2>
                    From development to digital marketing, our team provides a
                    wide range of solutions tailored to elevate your business.
                  </h2>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <div className="single-solution overly-one">
                      <div className="overly-two">
                        <h3>
                          <a href="solution-details.html">SEO Optimization</a>
                        </h3>
                        <p>
                          Enhance your online visibility and search engine
                          ranking with our expert SEO strategies.
                        </p>
                        <span>01</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="single-solution overly-one">
                      <div className="overly-two">
                        <h3>
                          <a href="solution-details.html">
                            WhatsApp Automation
                          </a>
                        </h3>
                        <p>
                          Streamline your customer interactions with our
                          WhatsApp automation solutions, offering efficient and
                          responsive communication.
                        </p>
                        <span>02</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="single-solution overly-one">
                      <div className="overly-two">
                        <h3>
                          <a href="solution-details.html">
                            Bulk Request Handling
                          </a>
                        </h3>
                        <p>
                          Efficiently manage high volumes of customer requests
                          and data processing with our robust bulk handling
                          solutions.
                        </p>
                        <span>03</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="single-solution overly-one">
                      <div className="overly-two">
                        <h3>
                          <a href="solution-details.html">CRM Solutions</a>
                        </h3>
                        <p>
                          Customized CRM solutions to streamline your business
                          processes and improve customer relationships.
                        </p>
                        <span>04</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 pr-0">
              <div className="solution-img">
                <img src="assets/img/solution-img.png" alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Solutions;

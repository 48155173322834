import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";

const websiteServices = [
  {
    imgUrl:
      "https://img.freepik.com/premium-vector/abstract-modern-ecommerce-logo-design-colorful-gradient-shopping-bag-logo-template_467913-995.jpg",
    name: "Ecommerce Website",
    description:
      "Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed sdfsdf sdfsd sdfdsf sdfdsf",
  },
  {
    imgUrl:
      "https://i2.wp.com/fribly.com/wp-content/uploads/2016/01/Free-Personal-Portfolio-Website-Template.png?fit=950%2C875&ssl=1",
    name: "Portfolio Website",
    description:
      "Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed sdfsdf sdfsd sdfdsf sdfdsf",
  },
  {
    imgUrl:
      "https://scontent.whatsapp.net/v/t39.8562-34/328238494_2566517096856341_5625165492518456552_n.jpg?ccb=1-7&_nc_sid=73b08c&_nc_ohc=_Fwvkslz8bYAX-ZM792&_nc_ht=scontent.whatsapp.net&oh=01_AdQyH3S9xnpPGWbHMS75Z-k7F4D4UFjVRHSQYtWsPYXXmw&oe=65732D25",
    name: "Whatsapp Business Managemet",
    description: "Whatsapp Business Managemet",
  },
  {
    imgUrl:
      "https://webthemez.com/wp-content/uploads/2017/10/cambridge-education-website-template-free-1.jpg",
    name: "Educational Website",
    description: "Educational Website, for School, Colleges annd institutions",
  },
  {
    imgUrl:
      "https://i.pinimg.com/236x/2c/b2/08/2cb208b22c09be02e291581f795def9a.jpg",
    name: "Real Estate Website",
    description: "Removal of Malware",
  },
  {
    imgUrl:
      "https://neetable.com/img/solutions/food-delivery-app-development/food-delivery-banner.png",
    name: "Food Delivery App",
    description: "Removal of Malware",
  },
  {
    imgUrl: "assets/img/services/services-2.jpg",
    name: "Website Scanning",
    description: "Removal of Malware",
  },
];

function Services() {
  return (
    <div>
      {/* <!-- Start Header Area --> */}
      <Header />
      {/* <!-- End Header Area --> */}

      {/* <!-- Start Page Title Area --> */}
      <div className="page-title-area page-title-style-two">
        <div className="container">
          <div className="page-title-content">
            <h2>Professional App and Website Builders</h2>
            <ul>
              <li>
                <Link to="/">
                  <i className="bx bx-home"></i>
                  Home
                </Link>
              </li>
              <li className="active">Professional App and Website Builders</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- End Page Title Area --> */}

      {/* <!-- Start Our Approach Area --> */}
      <section className="our-approach-area-three pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>Our approach</span>
            <h2>We Help Innovators Build Technology</h2>
          </div>

          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-approach-box overly-one">
                <div className="overly-two">
                  <div className="icon">
                    <i className="flaticon-key"></i>
                  </div>

                  <h3>Identifying Requirement</h3>
                  <p>
                    Knowing the exact requirement of customer for their business
                  </p>

                  <div className="approach-shape">
                    <img src="assets/img/approach-shape.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-approach-box overly-one">
                <div className="overly-two">
                  <div className="icon">
                    <i className="flaticon-padlock"></i>
                  </div>

                  <h3>Suggesting them better</h3>
                  <p>
                    Suggesting the customer the best possible solution based on
                    prior knowledge od businesses and other clients served
                  </p>

                  <div className="approach-shape">
                    <img src="assets/img/approach-shape.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-approach-box overly-one">
                <div className="overly-two">
                  <div className="icon">
                    <i className="flaticon-url"></i>
                  </div>

                  <h3>Finalizing the Structure</h3>
                  <p>
                    Finalising the structure of the App and website for the
                    business giving customer demo and blueprints for the
                    application
                  </p>

                  <div className="approach-shape">
                    <img src="assets/img/approach-shape.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-approach-box overly-one">
                <div className="overly-two">
                  <div className="icon">
                    <i className="flaticon-antivirus"></i>
                  </div>

                  <h3>On Time Delivery</h3>
                  <p>
                    Delivering the App to customer and helping the business grow
                  </p>

                  <div className="approach-shape">
                    <img src="assets/img/approach-shape.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Our Approach Area --> */}

      {/* <!-- Start Services Area --> */}
      <section className="services-area pb-70">
        <div className="container">
          <div className="section-title">
            <span>Website services</span>
            <h2>
              You can protect your organization’s cybersecurity by services us
            </h2>
          </div>

          <div className="row">
            {websiteServices.map((service) => {
              return (
                <div className="col-lg-3 col-sm-6">
                  <div className="single-services" style={{ height: "300px" }}>
                    <div className="services-img" style={{ height: "300px" }}>
                      <a
                        href="services-details.html"
                        style={{ height: "300px" }}
                      >
                        <img
                          src={service.imgUrl}
                          alt="Image"
                          style={{ objectFit: "cover" }}
                        />
                      </a>
                    </div>

                    <div className="services-content">
                      <h3>
                        <a href="services-details.html">{service.name}</a>
                      </h3>
                      <p>
                        {/* Lorem ipsum dolor sit amet, con sectetur adipiscing elit seddo. */}
                        {service.description}
                      </p>

                      <a href="services-details.html" className="read-more">
                        Read more
                        <i className="flaticon-right-arrow"></i>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-2.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Malware Removal</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read more
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-3.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Cloud Security</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read more
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-4.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Data Protection</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read more
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-5.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Application Security</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read more
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-6.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Threat Hunter</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read more
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-7.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Secure Managed IT</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read more
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-services">
                <div className="services-img">
                  <a href="services-details.html">
                    <img src="assets/img/services/services-8.jpg" alt="Image" />
                  </a>
                </div>

                <div className="services-content">
                  <h3>
                    <a href="services-details.html">Networking Security</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed
                    do.
                  </p>

                  <a href="services-details.html" className="read-more">
                    Read more
                    <i className="flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Area --> */}

      {/* <!-- Start Footer Area --> */}
      <Footer />
      {/* <!-- End Footer Area --> */}
    </div>
  );
}

export default Services;

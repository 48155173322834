const BlogArea = () => {
  return (
    <section className="blog-area blog-area-four pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span>Latest Insights</span>
          <h2>Explore Our Recent Articles & Updates</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="blog-img">
                <a href="blog-details.html">
                  <img src="assets/img/blog/blog-1.jpg" alt="Image" />
                </a>
              </div>

              <div className="blog-content">
                <span>April 15, 2024</span>
                <h3>
                  <a href="blog-details.html">Innovations in App Development</a>
                </h3>
                <p>
                  Dive into the latest advancements in mobile app technology and
                  how they're revolutionizing user experience.
                </p>

                <a href="blog-details.html" className="read-more">
                  Read More
                  <i className="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="blog-img">
                <a href="blog-details.html">
                  <img src="assets/img/blog/blog-2.jpg" alt="Image" />
                </a>
              </div>

              <div className="blog-content">
                <span>March 30, 2024</span>
                <h3>
                  <a href="blog-details.html">E-commerce Trends to Watch</a>
                </h3>
                <p>
                  Discover the key trends shaping the e-commerce industry in
                  2024 and how businesses can adapt to stay ahead.
                </p>

                <a href="blog-details.html" className="read-more">
                  Read More
                  <i className="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
            <div className="single-blog">
              <div className="blog-img">
                <a href="blog-details.html">
                  <img src="assets/img/blog/blog-3.jpg" alt="Image" />
                </a>
              </div>

              <div className="blog-content">
                <span>March 10, 2024</span>
                <h3>
                  <a href="blog-details.html">Leveraging CRM for Growth</a>
                </h3>
                <p>Understand how effective CRM integration</p>
                <a href="blog-details.html" className="read-more">
                  Read More
                  <i className="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogArea;

const Banner = () => {
  return (
    <section
      className="banner-area banner-area-five bg-5 jarallax"
      data-jarallax='{"speed": 0.3}'
    >
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="banner-content">
              <img
                src="assets/img/logonew.png"
                className="wow fadeInDown"
                data-wow-delay="1s"
                alt="Image"
                style={{ width: 80, height: 80 }}
              />
              <span className="top-title wow fadeInDown" data-wow-delay="1s">
                Innovating Web & App Development
              </span>
              <h1 className="wow fadeInDown" data-wow-delay="1s">
                Tailoring Digital Experiences for Tomorrow
              </h1>
              <p className="wow fadeInUp" data-wow-delay="1s">
                Crafting state-of-the-art web and mobile applications to elevate
                your business in the digital era. From e-commerce to customized
                app solutions, we're your gateway to technological advancement.
              </p>

              <div className="banner-btn wow fadeInUp" data-wow-delay="1s">
                <a href="about.html" className="default-btn">
                  <span>Learn More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

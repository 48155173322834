import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from './Footer'
import Header from './Header'

function AboutUs() {

  const options = {
    items: 1,
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    navText: [
      "<i class='flaticon-left-arrow'></i>",
      "<i class='flaticon-right-arrow'></i>",
    ],
  }

  const partnerSlider = {
    loop: true,
    margin: 30,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 2,
      },
      576: {
        items: 3,
      },
      768: {
        items: 4,
      },
      1200: {
        items: 5,
      }
    }
  }


  return (
    <div>
      {/* <!-- Links of JS files --> */}
      {/* <!-- Start Header Area --> */}
      <Header />
      {/* <!-- End Header Area --> */}

      {/* <!-- Start Page Title Area --> */}
      <div className="page-title-area page-title-style-two">
        <div className="container">
          <div className="page-title-content">
            <h2>About Us</h2>
            <ul>
              <li>
                <Link to="/">
                  <i className="bx bx-home"></i>
                  Home
                </Link>
              </li>
              <li className="active">About Us</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- End Page Title Area --> */}

      {/* <!-- Start About Us Area --> */}
      <section className="about-us-area pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img src="assets/img/about-img.jpg" alt="Image" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content">
                <div className="about-title">
                  <span>About Us</span>
                  <h2>Without stopping for a moment we give you best technology experience discussing from our expertise to stop threats being theft or damaged.</h2>
                </div>

                <div className="tab">
                  <ul className="tabs">
                    <li>
                      Our Experience
                    </li>
                    <li>
                      Why Us?
                    </li>
                    <li>
                      Our Approach
                    </li>
                  </ul>

                  <div className="tab_content">
                    <div className="tabs_item">
                      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat atque quibusdam fuga natus non necessitatibus eveniet maiores nostrum esse ut voluptates sint dolores, voluptatum consequatur ad est enim perferendis reprehenderit.</p>

                      <ul>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Trusted Partner
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Product Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          System Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Operational Security
                        </li>
                      </ul>
                    </div>

                    <div className="tabs_item">
                      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat atque quibusdam fuga natus non necessitatibus eveniet maiores nostrum esse ut voluptates sint dolores, voluptatum consequatur ad est enim perferendis reprehenderit.</p>

                      <ul>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Trusted Partner
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Product Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          System Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Operational Security
                        </li>
                      </ul>
                    </div>

                    <div className="tabs_item">
                      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat atque quibusdam fuga natus non necessitatibus eveniet maiores nostrum esse ut voluptates sint dolores, voluptatum consequatur ad est enim perferendis reprehenderit.</p>

                      <ul>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Trusted Partner
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Product Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          System Security
                        </li>
                        <li>
                          <i className="bx bx-check-circle"></i>
                          Operational Security
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Area --> */}

      {/* <!-- Start Our Challenges Area --> */}
      <section className="challenges-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
        <div className="container">
          <div className="section-title white-title">
            <span>Our Challenges</span>
            <h2>You can protect your organization’s cybersecurity by us</h2>
          </div>

          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-challenges overly-one">
                <div className="overly-two">
                  <i className="flaticon-threat"></i>
                  <h3>Identifying Threats</h3>
                  <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                  <span className="flaticon-threat"></span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-challenges overly-one">
                <div className="overly-two">
                  <i className="flaticon-cyber"></i>
                  <h3>Cyber Risk Assessment</h3>
                  <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                  <span className="flaticon-cyber"></span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-challenges overly-one">
                <div className="overly-two">
                  <i className="flaticon-cyber-security-1"></i>
                  <h3>Testing Cyber Security</h3>
                  <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                  <span className="flaticon-cyber-security-1"></span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-challenges overly-one">
                <div className="overly-two">
                  <i className="flaticon-password"></i>
                  <h3>Managing Cloud Security</h3>
                  <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                  <span className="flaticon-password"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Our Challenges Area --> */}

      {/* <!-- Start Protect Area --> */}
      <section className="protect-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="protect-img">
                <img src="assets/img/protect-img.jpg" alt="Image" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="protect-content">
                <div className="protect-title">
                  <span>Cyber Security Protect</span>
                  <h2>Protect your website, web server, and web application for helping you being threats from the hacker</h2>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <div className="single-challenges overly-one">
                      <div className="overly-two">
                        <i className="flaticon-database"></i>
                        <h3>Database Security</h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <span className="flaticon-database"></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6">
                    <div className="single-challenges overly-one">
                      <div className="overly-two">
                        <i className="flaticon-application"></i>
                        <h3>Database Security</h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <span className="flaticon-application"></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6">
                    <div className="single-challenges overly-one">
                      <div className="overly-two">
                        <i className="flaticon-security"></i>
                        <h3>Web Security</h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <span className="flaticon-security"></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6">
                    <div className="single-challenges overly-one">
                      <div className="overly-two">
                        <i className="flaticon-security-1"></i>
                        <h3>Server Security</h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <span className="flaticon-security-1"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Eed Protect Area --> */}

      {/* <!-- Start Testimonials Area --> */}
      <section className="testimonials-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
        <div className="container">
          <div className="testimonials">
            <span>What our customers say</span>
            <OwlCarousel className="testimonials-slider owl-carousel owl-theme"  {...options}>
              <div className="testimonials-item">
                <i className="flaticon-quote"></i>
                <p>“Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”</p>

                <ul>
                  <li>
                    <i className="bx bxs-star"></i>
                  </li>
                  <li>
                    <i className="bx bxs-star"></i>
                  </li>
                  <li>
                    <i className="bx bxs-star"></i>
                  </li>
                  <li>
                    <i className="bx bxs-star"></i>
                  </li>
                  <li>
                    <i className="bx bxs-star"></i>
                  </li>
                </ul>

                <h3>Jastin Anderson</h3>
                <span>CEO</span>
              </div>
              <div className="testimonials-item">
                <i className="flaticon-quote"></i>
                <p>“Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”</p>

                <ul>
                  <li>
                    <i className="bx bxs-star"></i>
                  </li>
                  <li>
                    <i className="bx bxs-star"></i>
                  </li>
                  <li>
                    <i className="bx bxs-star"></i>
                  </li>
                  <li>
                    <i className="bx bxs-star"></i>
                  </li>
                  <li>
                    <i className="bx bxs-star"></i>
                  </li>
                </ul>

                <h3>Juhon Anderson</h3>
                <span>Manager</span>
              </div>

            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* <!-- Eed Testimonials Area --> */}

      {/* <!-- Start Partner Area --> */}
      <div className="partner-area bg-color ptb-70">
        <div className="container">
          <OwlCarousel className="partner-slider owl-theme owl-carousel"  {...partnerSlider}>
            <div className="partner-item">
              <a href="#">
                <img src="assets/img/partner/partner-1.png" alt="Image" />
              </a>
            </div>

            <div className="partner-item">
              <a href="#">
                <img src="assets/img/partner/partner-2.png" alt="Image" />
              </a>
            </div>

            <div className="partner-item">
              <a href="#">
                <img src="assets/img/partner/partner-3.png" alt="Image" />
              </a>
            </div>

            <div className="partner-item">
              <a href="#">
                <img src="assets/img/partner/partner-4.png" alt="Image" />
              </a>
            </div>

            <div className="partner-item">
              <a href="#">
                <img src="assets/img/partner/partner-5.png" alt="Image" />
              </a>
            </div>
          </OwlCarousel>
        </div>
      </div>
      {/* <!-- End Partner Area --> */}
      <Footer />

    </div >
  )
}

export default AboutUs
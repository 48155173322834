import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div>
      {/* <!-- Start Header Area --> */}
      <header className="header-area">
        {/* <!-- Start Top Header --> */}
        <div className="top-header top-header-five">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6">
                <ul className="header-left-content">
                  <li>
                    <i className="bx bx-phone-call"></i>
                    <a href="tel:+1-(514)-312-5678">+91 8448804428</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-6 col-sm-6">
                <ul className="header-right-content">
                  <li>
                    <a href="#" target="_blank">
                      <i className="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/innovatewebsitesolutions/"
                      target="_blank"
                    >
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="bx bxl-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="bx bxl-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Start Top Header --> */}

        {/* <!-- Start Navbar Area --> */}
        <div className="navbar-area navbar-area-five">
          <div className="mobile-nav">
            <div className="container">
              <Link to="/" className="logo">
                <div style={{ display: "flex", alignItems: "end", gap: 2 }}>
                  <img
                    src="assets/img/logonew.png"
                    alt="Logo"
                    style={{ width: 50, height: 50 }}
                  />
                  <h3 style={{ color: "white" }}>Unicron Apps</h3>
                </div>
              </Link>
            </div>
          </div>

          <div className="main-nav">
            <div className="container">
              <nav className="navbar navbar-expand-md">
                <Link className="navbar-brand" to="/">
                  <div style={{ display: "flex", alignItems: "end", gap: 2 }}>
                    <img
                      src="assets/img/logonew.png"
                      alt="Logo"
                      style={{ width: 50, height: 50 }}
                    />
                    <h3 style={{ color: "white" }}>Unicron Apps</h3>
                  </div>
                </Link>

                <div className="collapse navbar-collapse mean-menu">
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item">
                      <Link className="nav-Link" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-Link" to="/services">
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/solutions" className="nav-Link">
                        Solutions
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="#" className="nav-Link">
                        About Us <i className="bx bx-chevron-down"></i>
                      </Link>

                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to="/about" className="nav-Link">
                            About Us
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/team" className="nav-Link">
                            Team
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/testimonials" className="nav-Link">
                            Testimonials
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link to="/contact" className="nav-Link">
                        Contact Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="#" className="nav-Link">
                        More <i className="bx bx-chevron-down"></i>
                      </Link>

                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to="/faq" className="nav-Link">
                            FAQ
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/privacy-policy" className="nav-Link">
                            Privacy Policy
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/terms-conditions" className="nav-Link">
                            Terms & Conditions
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>

          <div className="others-option-for-responsive">
            <div className="container">
              <div className="dot-menu">
                <div className="inner">
                  <div className="circle circle-one"></div>
                  <div className="circle circle-two"></div>
                  <div className="circle circle-three"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Navbar Area --> */}
      </header>
      {/* <!-- End Header Area --> */}

      {/* <Content /> */}
    </div>
  );
}

export default Header;

const Challenges = () => {
  return (
    <section className="challenges-area challenges-area-two pt-100 pb-70">
      <div className="container">
        <div className="section-title white-title">
          <span>Our Challenges</span>
          <h2>Navigating the Complexities of Modern Web & App Development</h2>
        </div>

        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="single-challenges overly-one">
              <div className="overly-two">
                <i className="flaticon-threat"></i>
                <h3>Advanced Technology Integration</h3>
                <p>
                  Adapting to rapidly evolving technologies to deliver
                  state-of-the-art web and app solutions.
                </p>
                <span className="flaticon-threat"></span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-challenges overly-one">
              <div className="overly-two">
                <i className="flaticon-cyber"></i>
                <h3>Seamless User Experience</h3>
                <p>
                  Ensuring intuitive and engaging user experiences across all
                  digital platforms.
                </p>
                <span className="flaticon-cyber"></span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-challenges overly-one">
              <div className="overly-two">
                <i className="flaticon-cyber-security-1"></i>
                <h3>Scalable Solution Development</h3>
                <p>
                  Creating scalable and flexible web and app solutions that grow
                  with your business.
                </p>
                <span className="flaticon-cyber-security-1"></span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-challenges overly-one">
              <div className="overly-two">
                <i className="flaticon-password"></i>
                <h3>Data Security and Privacy</h3>
                <p>
                  Prioritizing data security and privacy in every web and app
                  solution we develop.
                </p>
                <span className="flaticon-password"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="challenges-shape">
        <img src="assets/img/challenges-shape.png" alt="Image" />
      </div>
    </section>
  );
};

export default Challenges;

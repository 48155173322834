import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Partner = () => {
  const partnerSlider = {
    loop: true,
    margin: 30,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 2,
      },
      576: {
        items: 3,
      },
      768: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };

  return (
    <div className="partner-area bg-color ptb-100">
      <div className="container">
        <OwlCarousel
          className="partner-slider owl-theme owl-carousel"
          {...partnerSlider}
        >
          <div className="partner-item">
            <a href="#">
              <img src="assets/img/partner/partner-1.png" alt="Image" />
            </a>
          </div>

          <div className="partner-item">
            <a href="#">
              <img src="assets/img/partner/partner-2.png" alt="Image" />
            </a>
          </div>

          <div className="partner-item">
            <a href="#">
              <img src="assets/img/partner/partner-3.png" alt="Image" />
            </a>
          </div>

          <div className="partner-item">
            <a href="#">
              <img src="assets/img/partner/partner-4.png" alt="Image" />
            </a>
          </div>

          <div className="partner-item">
            <a href="#">
              <img src="assets/img/partner/partner-5.png" alt="Image" />
            </a>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Partner;

import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

function Testimonial() {
    return (
        <div>
            {/* <!-- Start Header Area --> */}
            <Header />
            {/* <!-- End Header Area --> */}
            {/* <!-- Start Page Title Area --> */}
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Testimonials</h2>
                        <ul>
                            <li>
                                <Link to="/">
                                    <i className="bx bx-home"></i>
                                    Home
                                </Link>
                            </li>
                            <li className="active">Testimonials</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <!-- End Page Title Area --> */}

            {/* <!-- Start Testimonials Area --> */}
            <section className="testimonials-area testimonials-page-area pt-100 pb-70">
                <div className="container">
                    <div className="section-title">
                        <span>Testimonials</span>
                        <h2>What our customers say</h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="testimonials-item">
                                <i className="flaticon-quote"></i>
                                <p>“Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”</p>

                                <ul>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                </ul>

                                <h3>Jastin Anderson</h3>
                                <span>CEO</span>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="testimonials-item">
                                <i className="flaticon-quote"></i>
                                <p>“Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”</p>

                                <ul>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                </ul>

                                <h3>Juhon Anderson</h3>
                                <span>Manager</span>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="testimonials-item">
                                <i className="flaticon-quote"></i>
                                <p>“Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”</p>

                                <ul>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                </ul>

                                <h3>Kliva</h3>
                                <span>Marketer</span>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="testimonials-item">
                                <i className="flaticon-quote"></i>
                                <p>“Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”</p>

                                <ul>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                </ul>

                                <h3>Jon Smith</h3>
                                <span>CEO IT</span>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="testimonials-item">
                                <i className="flaticon-quote"></i>
                                <p>“Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”</p>

                                <ul>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                </ul>

                                <h3>Admam Smith</h3>
                                <span>Covered</span>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="testimonials-item">
                                <i className="flaticon-quote"></i>
                                <p>“Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”</p>

                                <ul>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                    <li>
                                        <i className="bx bxs-star"></i>
                                    </li>
                                </ul>

                                <h3>Alex</h3>
                                <span>Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Eed Testimonials Area --> */}
            {/* <!-- Start Footer Area --> */}
            <Footer />
            {/* <!-- End Footer Area --> */}
        </div>
    )
}

export default Testimonial
const CounterArea = () => {
  return (
    <section
      className="counter-area pt-100 pb-70 jarallax"
      data-jarallax='{"speed": 0.3}'
    >
      <div className="container">
        <div className="counter-max-wide">
          <div className="section-title white-title">
            <span>Our Achievements</span>
            <h2>
              With our commitment to innovation and excellence in web and app
              development, we have achieved significant milestones.
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="single-counter overly-one">
                <div className="overly-two">
                  <i className="flaticon-authentication"></i>
                  <h2>
                    <span
                      className="odometer"
                      class="prg-count"
                      data-count="500" // Adjusted figure
                    >
                      00
                    </span>
                  </h2>
                  <h3>Successful Projects</h3> {/* Updated title */}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-sm-6">
              <div className="single-counter overly-one">
                <div className="overly-two">
                  <i className="flaticon-reliability"></i>
                  <h2>
                    <span
                      className="odometer"
                      class="prg-count"
                      data-count="300" // Adjusted figure
                    >
                      00
                    </span>
                  </h2>
                  <h3>Satisfied Clients</h3> {/* Updated title */}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-sm-6">
              <div className="single-counter overly-one">
                <div className="overly-two">
                  <i className="flaticon-web-protection"></i>
                  <h2>
                    <span
                      className="odometer"
                      class="prg-count"
                      data-count="750" // Adjusted figure
                    >
                      00
                    </span>
                  </h2>
                  <h3>App Downloads</h3> {/* Updated title */}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-sm-6">
              <div className="single-counter overly-one">
                <div className="overly-two">
                  <i className="flaticon-innovation"></i>
                  <h2>
                    <span
                      className="odometer"
                      class="prg-count"
                      data-count="95" // Adjusted figure
                    >
                      00
                    </span>
                    <span className="target">%</span>
                  </h2>
                  <h3>Customer Satisfaction Rate</h3> {/* Updated title */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterArea;

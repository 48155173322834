import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import Header from './components/Header';
import Home from "./components/Home/Home";
import Services from "./components/Services/Services";
import Solutions from "./components/Solutions";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Team from "./components/Team";
import Testimonial from "./components/Testimonial";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsCondition from "./components/TermsCondition";
// import Faq from './components/Faq';
import FileNot from "./components/FileNot";
import InnovationsInAppDevelopmentBlog from "./components/Blog/InnovationsInAppDevelopmentBlog";

function App() {
  // const authenticate = () => {
  //   return new Promise(resolve => setTimeout(resolve, 2000)) // 2 seconds
  // }

  // useEffect(() => {
  //   authenticate().then(() => {
  //     const ele = document.getElementById('ipl-progress-indicator')
  //     ele.style.display = "none"
  //     if (ele) {
  //       ele.outerHTML = ''
  //     }
  //   })
  // })

  return (
    <>
      {/* <div className="preloader" id="ipl-progress-indicator">
        <div className="loader">
          <div className="loader-outter"></div>
          <div className="loader-inner"></div>
          <div className="indicator">
            <svg width="16px" height="12px">
              <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
              <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
            </svg>
          </div>
        </div>
      </div> */}
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/solutions" component={Solutions} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/testimonials" component={Testimonial} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-conditions" component={TermsCondition} />
          <Route
            exact
            path="/blog/InnovationsInAppDevelopmentBlog"
            component={InnovationsInAppDevelopmentBlog}
          />
          {/* <Route exact path="/faq" component={Faq} /> */}
          <Route component={FileNot} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
